.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.banner {
		position: relative;
		min-height: 360px;
		width: 100%;

		@media all and (max-width: 768px) {
			min-height: 180px;
		}
	}

	.faqContent {
		margin: 40px 0;
	}
	.contactUsBlock {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 100px auto 0;
		text-align: center;

		max-width: 500px;

		h2 {
			font-weight: 600;
			margin: 0;
			font-size: 32px;
		}
		p {
			margin: 15px 0 25px;
		}
	}

	.tabs {
		flex: 1;
		width: 100%;
		height: 100%;

		margin: 40px 0;

		.list {
			padding: 10px 0;
			flex: 1;
			/*background-color: green;*/
			&:before {
				display: none;
			}

			.tab {
				border-left-width: 4px;
				border-radius: 0;
				border-right: 0;
				padding: 15px;
				min-height: 64px;
				text-wrap: initial;

				&[data-active] {
					color: #ec1c46;
				}
			}
		}
	}
}
