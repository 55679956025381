.root {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px 16px;

    &[data-has-pills='false'] {
        display: none;
    }
}

.removeAllButtonRoot {
    height: 32px;
    padding: 0;
}

.removeAllButtonLabel {
    color: #0061AF;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}


.pillLabel {
    color: #0A2432;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px; /* 171.429% */
}

.pillRemove {
    color: #8493A8;
}