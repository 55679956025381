.container {
	width: 100%;
	padding: 1rem;
	margin-top: 124px;

	display: grid;
	gap: 48px;
	grid-template-columns: 1fr auto;
	align-items: center;

	.carousel {
		grid-column: span 2;
	}

	.title {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 40px;
		font-style: normal;
		font-weight: 500;
		line-height: 60px;
		color: var(--primary-black);
		margin: 0;
	}

	@mixin smaller-than $mantine-breakpoint-sm {
		grid-template-columns: 1fr;
		margin-top: 80px;
		gap: 32px 0;

		.title {
			font-size: 20px;
			font-weight: 500;
			line-height: 28px;
		}

		.viewAllButton {
			order: 3;
		}
	}
}