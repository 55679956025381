.sectionContent {

	.decodedImage {
		border-radius: 16px;
		height: 222px;
		width: 222px;
		margin: 4px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
		}
	}

	.header {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		gap: 20px;

		@mixin smaller-than $mantine-breakpoint-md {
			display: none;
		}

		.item {
			display: flex;
			gap: 10px;
			justify-content: space-between;
		}
	}

	.loadMoreRoot {
		display: flex;
		justify-content: center;
		margin: 20px auto;
		&:hover {
			background-color: #c11a3f;
		}
	}

	.paginationWrapper {
		display: flex;
		justify-content: center;
		margin: 20px auto;
	}
}
