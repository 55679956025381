.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    row-gap: 32px;
    margin: 0;
    width: 100%;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 10px;
        row-gap: 20px;
    }

    @media (max-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 8px;
        row-gap: 24px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8px;
        row-gap: 24px;
    }
}
