.section {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 32px;
        margin-bottom: 48px;

        .headerText {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            max-width: 550px;
        }

        .sectionTitle {
            border-radius: 8px;
            padding: 4px 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            background: #F4F9FD;
            color: #0061AF;
        }

        .sectionSubTitle {
            font-size: 40px;
            font-weight: 600;
            line-height: 60px;
            margin: 0;
        }

        .headerDescription {
            max-width: 40%;
            margin: 0;
        }


        @mixin smaller-than $mantine-breakpoint-md {
            flex-direction: column;
            margin-bottom: 32px;
            gap: 8px;

            .headerText, .headerDescription {
                max-width: 100%;
                width: 100%;
            }

            .sectionSubTitle {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        gap: 48px;

        figure {
            margin: 0;
            width: 50%;

            img {
                border-radius: 16px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .services {
            width: 50%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 32px;

            list-style: none;
            padding: 0;
            margin: 0;
        }

        @mixin smaller-than $mantine-breakpoint-md {
            flex-direction: column;
            gap: 32px;

            figure {
                width: 100%;
            }

            .services {
                width: 100%;
                gap: 24px;
            }
        }
    }

}