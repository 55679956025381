.root {
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	display: grid;
}

.buttonRoot {
	height: 52px;
	padding: 12px 20px;
}

.buttonLabel {
	color: #0061AF;
	font-size: 16px;
	font-weight: 500;
	line-height: 28px; /* 175% */
}

.buttonSection[data-position="left"] {
	margin-right: 8px;
}

.drawerBody {
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.drawerHeader {
	padding: 8px 16px 8px 24px;
	border-bottom: 1px solid #ECEFF4;

	.drawerTitle {
		color: #0A2432;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px; /* 140% */
	}
}
