.main {
    padding: 40px 0;

    .list {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @media all and (max-width: 992px) {
            gap: 32px;
        }
    }
}

.mobile {
    display: none;
}

@media all and (max-width: 992px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}

