.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 40px 0;

	.content {
	}
}

.mobile {
	display: none;
}

@media all and (max-width: 992px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}
}
