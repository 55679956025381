.actions {
    padding: 12px 16px;
    background-color: var(--white);
    box-shadow: 0px 0px 4px 0px rgba(7, 59, 102, 0.04), 0px 0px 20px 0px rgba(7, 59, 102, 0.06);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    &.opened{
        box-shadow: none;
    }

    .contentWrapper {
        flex: 1;
    }

    .toggleButton {
        --button-padding-x: 16px!important;
        svg{
            transition: transform 0.3s ease;
        }

        &.rotated {
            svg{
                transform: rotate(180deg);
            }
        }
    }
}

.drawerRoot {
    z-index: 10 !important;
    position: relative;
    border-radius: 12px;
}

.drawerContent {
    border-radius: 16px;
}

.drawerClose {
    position: absolute;
    right: 4px;
    top: 4px;
    background: var(--white);
    width: 40px;
    height: 40px;
    color: var(--grey-4);
}

.drawerHeader {
    min-height: auto;
    padding: 0;
}

.drawerBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 16px 100px;
    border-radius: 12px;

    .drawerImage {
        border-radius: 12px;
        width: 264px;
        height: 264px;
        margin: 0 auto;
    }
}