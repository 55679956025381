.mobileToggle {
    @mixin larger-than $mantine-breakpoint-md {
        display: none;
    }
}

.mobileAccordion {
    display: none;

    .desktopContent {
        display: block;
    }

    .accordionText {
        font-weight: 500;
        font-size: 16px;
        padding: 15px 0;
        display: block;
        width: 100%;
        text-align: left;
        background: none;
        border: none;
        cursor: pointer;
    }

    @mixin smaller-than $mantine-breakpoint-md {
        display: block;

        .accordionContent {
            padding: 0;
        }

        .accordionLabel {
            padding: 0;
        }

        .desktopContent {
            display: none;
        }

        .mobileToggle {
            display: none;
        }
    }
}

.desktopContent {
    display: block;

    @mixin smaller-than $mantine-breakpoint-md {
        display: none;
    }
}

.container {
    display: flex;
    flex-direction: column;
    border: solid 1px #dee2e6;
    border-radius: 4px;

    @mixin smaller-than $mantine-breakpoint-md {
        border-radius: 0px;
        border: 0;
        border-top: solid 1px #dee2e6;
    }
}