.list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;

	@mixin smaller-than $mantine-breakpoint-sm {
		gap: 8px;
	}

	.listItem {
		display: flex;
		gap: 5px;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 14px;
		line-height: 21px;
		color: #959595;

		.root {
			border: 2px solid transparent;
		}

		.selected {
			border-color: #0a62ae;
		}

		.radioContainer {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			min-width: 20px;
			border-radius: 0;

			.iconWrapper {
				width: 60px;
				height: 60px;
				position: relative;
			}
			.icon {
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
				border-radius: 8px;
			}

			.label {
				color: var(--primary-black);
				padding: 8px 12px;
				border-radius: 8px;
				border: 1px solid var(--grey-2);
				line-height: 28px;
			}

			@mixin smaller-than $mantine-breakpoint-sm {
				.iconWrapper {
					width: 48px;
					height: 48px;
				}
			}
		}
	}
}

.variants {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.variantsItem {
		display: flex;
		flex-direction: column;
		gap: 12px;
		color: var(--primary-black);
		.name {
			font-weight: 600;
		}
	}
}