.mobileCounter{
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title{
        font-size: 16px;
        line-height: 28px;

        .name{
            font-weight: 600;
        }
    }
    .content{
        display: flex;
        align-items: center;
        gap: 24px;
        .countInfo{
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: var(--blue-6);

        }
    }
}