.section {

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 16px;
        margin-bottom: 48px;

        @mixin smaller-than $mantine-breakpoint-md {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 32px;
        }

        .headerText {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
        }

        .sectionTitle {
            border-radius: 8px;
            padding: 4px 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            background: var(--blue-0);
            color: var(--secondary-blue);
        }

        .sectionSubTitle {
            font-size: 40px;
            font-weight: 600;
            line-height: 60px;
            margin: 0;

            @mixin smaller-than $mantine-breakpoint-lg {
                font-size: 32px;
                line-height: 48px;
            }

            @mixin smaller-than $mantine-breakpoint-md {
                font-size: 20px;
                line-height: 28px;

            }
        }

        .countries {
            @mixin smaller-than $mantine-breakpoint-md {
                position: relative;
                height: 70px;
                width: 100%;
            }

            .countryLabel {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;

                .flag {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    overflow: hidden;

                    background-size: contain;

                    &.china {
                        background: url("/icons/flags-rounded/china.svg") no-repeat center;
                    }
                    &.korea {
                        background: url("/icons/flags-rounded/korea.svg") no-repeat center;
                    }
                    &.japan {
                        background: url("/icons/flags-rounded/japan.svg") no-repeat center;
                    }
                }

            }
        }
    }
}