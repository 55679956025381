.sectionContent {
	display: flex;
	flex-direction: column;
	gap: 20px;
	@media all and (max-width: 992px) {
		gap:12px;
	}

	.decodedImage {
		border-radius: 16px;
		height: 222px;
		width: 222px;
		margin: 4px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.additional {
			color: #8493A8;
			font-size: 16px;
			font-weight: 400;
			line-height: 28px; /* 175% */
			margin: 0;

			@media all and (max-width: 992px) {
				color: #0061AF;
				font-size: 14px;
				line-height: 24px; /* 171.429% */
			}
		}

		@media all and (max-width: 992px) {
			.hideOnMobile {
				display: none;
			}
		}
	}

	.loadMoreRoot {
		display: flex;
		justify-content: center;
		margin: 20px auto;
		&:hover {
			background-color: #d4173d;
		}
	}

	.paginationWrapper {
		display: flex;
		justify-content: center;
		margin: 20px auto;
	}
}
