.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 16px 0 40px;
}


.mobile {
	display: none;
}

@media all and (max-width: 992px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}
}
