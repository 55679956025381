.container {
	display: flex;
	flex-direction: column;
	gap: 32px;

	@mixin smaller-than $mantine-breakpoint-sm {
		gap: 20px;
	}

	.top {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.favouriteBtn {
			border-radius: 16px;
			background: var(--blue-0);
			width: 60px;
			height: 60px;
			padding: 8px;
			&:hover {
				background: var(--blue-1);
			}
			&.active {
				svg {
					fill: var(--secondary-blue);
				}
			}
			@mixin smaller-than $mantine-breakpoint-sm {
				width: 48px;
				height: 48px;
			}
		}
	}

	.titleMob {
		display: none;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;

		.title {
			font-size: 14px;
			line-height: 24px;
			margin: 0;
			font-weight: 500;
			color: var(--primary-black);
		}

		.link {
			display: flex;
			align-items: center;
			gap: 8px;
			font-size: 14px;
			font-weight: 600;
			line-height: 24px;
			color: var(--secondary-blue);
			white-space: nowrap;
			text-decoration: none;
		}

		@mixin smaller-than $mantine-breakpoint-md {
			display: flex;
		}
	}

	.addBasket {
		display: flex;
		flex-wrap: wrap;
		gap: 16px 12px;
		max-width: 100%;
		overflow: hidden;

		.article {
			color: var(--primary-black);
			font-size: 16px;
			font-weight: 600;
			line-height: 28px;
			max-width: 100%;
			overflow: hidden;
			display: flex;
			margin-right: auto;

			span:first-child {
				font-weight: 600;
			}
		}

		.countInfo {
			color: var(--secondary-blue);
			text-align: end;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.addBasketActions {
			width: 100%;
			grid-column:  span 2;
			display: flex;
			gap: 12px;
			align-items: center;
            @mixin smaller-than $mantine-breakpoint-md {
                display: none;
            }
		}

		.button {
			width: 100%;
		}

		@mixin smaller-than $mantine-breakpoint-sm {
			gap: 4px;

			.button {
				font-size: 16px;
				padding: 12px;
			}

			.article, .countInfo {
				grid-column:  span 2;
				text-align: start;
			}

			.addBasketActions {
				/*padding: 12px 16px;*/
				/*background-color: var(--white);*/
				/*box-shadow: 0px 0px 4px 0px rgba(7, 59, 102, 0.04), 0px 0px 20px 0px rgba(7, 59, 102, 0.06);*/
				/*position: fixed;*/
				/*bottom: 0;*/
				/*left: 0;*/
				/*z-index: 11;*/
				/*width: 100%;*/
			}
		}


        .cartMobileFixedContent{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .content{
                display: flex;
                gap: 8px;
                .text{
                    display: flex;
                    flex-direction: column;


                    .label{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        text-decoration: line-through;
                        color: var(--grey-4);
                    }
                    .value{
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 28px;

                    }
                }
            }
        }
	}

	.countWrapper {
		box-shadow: none;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		display: flex;
		align-items: center;
		width: 100% !important;
	}
	.countSection {
		width: 48px;
		margin: 0;
	}

	.countButton {
		& > * {
			pointer-events: none;
		}

		&:hover * {
			fill: #ec1944;
		}
	}

	.countInput {
		width: 148px;
		height: 60px;
		padding: 0 36px;

		font-weight: 500;
		font-size: 20px;
		line-height: 32px;
		text-align: center;
		color: var(--secondary-blue);

		border-radius: 16px;
		border: 1px solid var(--grey-2);
		background: var(--white);
	}
}

.drawerRoot {
	z-index: 10 !important;
	position: relative;
	border-radius: 12px;
}

.drawerContent {
	border-radius: 16px;
}

.drawerClose {
	position: absolute;
	right: 4px;
	top: 4px;
	background: var(--white);
	width: 40px;
	height: 40px;
	color: var(--grey-4);
}

.drawerHeader {
	min-height: auto;
	padding: 0;
}

.drawerBody {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px 16px 100px;
	border-radius: 12px;

	.drawerImage {
		border-radius: 12px;
		width: 264px;
		height: 264px;
		margin: 0 auto;
	}
}