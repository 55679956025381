.service {
    display: block;
    padding: 24px;
    text-decoration: none;
    height: 100%;

    border-radius: 16px;
    background-color: var(--blue-0);
    border: 1px solid var(--blue-0);
    transition: border 250ms ease, box-shadow 250ms ease;

    @media (hover: hover) {
        &:hover {
            border: 1px solid var(--secondary-blue);
            box-shadow: 0 0 4px 0 rgba(7, 59, 102, 0.04), 0 0 20px 0 rgba(7, 59, 102, 0.06);

            .title {
                color: var(--secondary-blue);
            }
        }
    }
    @media (hover: none) {
        &:active {
            border: 1px solid var(--secondary-blue);
            box-shadow: 0 0 4px 0 rgba(7, 59, 102, 0.04), 0 0 20px 0 rgba(7, 59, 102, 0.06);

            .title {
                color: var(--secondary-blue);
            }
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        color: var(--secondary-blue);
        margin-bottom: 20px;

        .icon {
            border-radius: 16px;
            background-color: var(--blue-2);
            padding: 12px;
            width: 56px;
            height: 56px;
        }
    }

    .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        color: var(--primary-black);
        margin: 0 0 8px;
    }

    .description {
        font-size: 16px;
        line-height: 28px;
        color: var(--primary-black);
        margin: 0;
    }
}