.container {
	display: flex;
	justify-content: space-between;
	gap: 32px;
	flex: 1;
	width: 100%;
	overflow: hidden;

	@media all and (max-width: 992px) {
		flex-wrap: wrap;
	}
	.leftColumn {
		flex: 1 0 var(--left-column-width, 292px);
		max-width: var(--left-column-width, 292px);

		display: flex;
		flex-direction: column;
		gap: 12px;
		@media all and (max-width: 992px) {
			padding-top: 0;
			max-width: 100%;
			width: 100%;
			flex-basis: 100%;
		}
	}

	.rightColumn {
		flex: 1 0;
		width: 100%;

		display: flex;
		flex-direction: column;
		gap: 12px;
	}
}
