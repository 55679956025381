.skeleton {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	min-width: 100%;
	margin: 0;
	padding: 0;

	.skeletonHeader {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
	}
}

