.section {
    --Neutral-Black: #0A2432;
    --Primary-Red: #E60033;
    --Grey: #758295;
    --Primary-Blue: #0061AF;
    --Primary-White: #FFF;
    --Stroke-Gray: #DBE4F0;
    --Light-blue: #F4F9FD;
    --Hover-Blue: #468CC3;
    --Background-blue: #CDE0EF;
    font-family: Montserrat, sans-serif;
    color: var(--Neutral-Black, #0A2432);

    --desktop-min-height: 0;
    --mobile-min-height: 0;

    /****************/

    article {
        border-radius: 16px;
        overflow: hidden;
        background: var(--Background-blue, #CDE0EF);

        .content {
            min-height: calc(var(--mobile-min-height) / 2);
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 32px;
            padding: 32px 24px;
            align-items: flex-start;

            header {
                h2 {
                    color: var(--Primary-Blue, #0061AF);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 142.857% */
                    margin: 0 0 8px;
                }

                h3 {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px; /* 140% */
                    letter-spacing: -0.4px;
                    margin: 0;
                    color: var(--Neutral-Black, #0A2432);
                }
            }

            a {
                text-decoration: none;
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 16px;
                background: var(--Primary-Blue, #0061AF);

                color: var(--Primary-White, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
            }
        }

        .imageContainer {
            position: relative;
            height: calc(var(--mobile-min-height) / 2);
        }
    }

    @media (min-width: 992px) {
        article {
            min-height: var(--desktop-min-height);
            display: grid;
            grid-template-columns: 1fr 1fr;

            .content {
                min-height: inherit;
                padding: 86px 60px;

                header h3 {
                    font-size: 40px;
                    line-height: 60px; /* 150% */
                    letter-spacing: -0.8px;
                }
            }

            .imageContainer {
                height: inherit;
            }
        }
    }
}
