.skeletonCard {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 4px;
	background-color: #fafafa;
	border-radius: 16px;

	.skeletonImage {
		position: relative;
		width: 100%;
		padding-top: 100%;

		.skeletonImageRoot {
			position: absolute;
			top: 0;
		}
	}

	.skeletonText {
		padding: 0 8px 12px;
		display: flex;
		flex-direction: column;

		.skeletonPrice {
			margin-top: 12px;
			display: flex;
			align-items: flex-end;
			gap: 8px;
		}
	}
}
