.sectionContent {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .decodedImage {
        border-radius: 16px;
        height: 222px;
        width: 222px;
        margin: 4px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        .count {
            margin: 0;
            color: #8493A8;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px; /* 175% */
        }


        @media all and (max-width: 992px) {
            display: none;
        }
    }

    .loadMoreRoot {
        height: 48px;
        padding: 12px 20px;
        display: flex;
        justify-content: center;
        margin: 20px auto;
    }

    .loadMoreLabel {
        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px; /* 175% */
    }
}
