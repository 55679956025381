.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

    margin: 40px 0;

    @mixin smaller-than $mantine-breakpoint-md {

        margin: 22px 0;
    }
	.pageContent {
		padding: 0 20px;
	}

	.pageTitle {
		font-size: 24px;
		font-weight: 600;
		margin: 0;
	}
}
