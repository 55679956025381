.price {
    display: flex;
    align-items: center;
    gap: 12px;
    .current {
        color: var(--primary-black);
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
    }
    .discount {
        font-size: 28px;
        font-weight: 500;
        line-height: 40px;
        color: var(--grey-4);
    }

    @mixin smaller-than $mantine-breakpoint-sm {
        .current {
            font-size: 28px;
            line-height: 40px;
        }
        .discount {
            font-size: 20px;
            line-height: 28px;
        }
    }
}