.filtersContainer {
	display: flex;
	flex-direction: column;

	.title {
		margin: 0 0 16px;
		color: #0A2432;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px; /* 140% */

		@mixin smaller-than $mantine-breakpoint-md {
			font-size: 16px;
			font-weight: 600;
			line-height: 28px; /* 175% */
		}
	}

	.rangeInputs {
		margin-bottom: 24px;
		display: grid;
		align-items: center;
		grid-template-columns: 1fr 80px;
		gap: 12px;

		.input {
			height: 48px;
			padding: 0 16px;

			color: #0A2432;
			font-size: 16px;
			font-weight: 400;
			line-height: 28px; /* 175% */
		}

		.inputsContainer {
			color: #CAD3DF;
			display: grid;
			align-items: center;
			grid-template-columns: 1fr auto 1fr;
			gap: 8px;
		}
	}

	.buttonLabel {
		font-size: 16px;
		font-weight: 500;
		line-height: 28px; /* 175% */
	}

	.rangeSliderTrack {
		background-color: #F4F9FD;
	}

	.rangeSliderThumb {
		width: 20px;
		height: 20px;
	}
}
