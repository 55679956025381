.sectionContent {
    display: grid;
    width: 100%;
    padding: 32px;
    grid-template-columns: auto 1fr auto;
    gap: 60px;
	border-radius: 16px;
	background: #FFF;
	box-shadow: 0 0 4px 0 rgba(7, 59, 102, 0.04), 0 0 20px 0 rgba(7, 59, 102, 0.06);
    align-items: center;

    @media screen and (max-width: 991px) {
        padding: 24px;
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .pageTitle {
        margin: 0;
        color: #0A2432;
        font-size: 40px;
        font-weight: 500;
        line-height: 60px; /* 150% */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 991px) {
            font-size: 28px;
            line-height: 40px; /* 142.857% */
        }
    }

    .shopInfo {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 8px 60px;
        list-style: none;
        padding: 0;
        margin: 0;

        @mixin smaller-than $mantine-breakpoint-sm {
            grid-template-columns: 1fr;
        }

        li {
            display: flex;
            gap: 4px;

            .title {
                color: #8493A8;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px; /* 175% */
                white-space: nowrap;
                margin: 0;
                padding: 0;
            }

            .value {
                color: #0A2432;
                font-size: 16px;
                font-weight: 400;
                line-height: 28px; /* 175% */
                margin: 0;
                padding: 0;
            }
        }
    }

    .favouriteButtonRoot {
        padding: 10px 20px;

        .favouriteButtonLabel {
            color: #0061AF;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px; /* 175% */
        }

        .favouriteButtonSection[data-position="right"] {
            margin-left: 8px;;
        }
    }
}
