.buttonRoot {
    height: 52px;
    padding: 12px 20px;

    .buttonLabel {
        color: #0061AF;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px; /* 175% */
    }

    .buttonSection[data-position="left"] {
        margin-right: 8px;
    }
}

.drawerContent {
    border-radius: 16px 16px 0 0;

    .drawerHeader {
        padding: 8px 16px 8px 24px;
        border-bottom: 1px solid #ECEFF4;

        .drawerTitle {
            color: #0A2432;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px; /* 140% */
        }
    }

    .sortDrawerBody {
        padding: 24px 16px;
    }
}

.sortRadioItem {
    padding: 12px;
    font-size: 16px;

    &[data-checked="true"] {
        .sortRadioItemLabel {
            color: #0061AF;
        }
    }

    .sortRadioItemInner {
        display: none;
    }

    .sortRadioItemLabel {
        color: #0A2432;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px; /* 175% */
        padding: 0;
    }
}
