.card {
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    gap: 12px;
    color: inherit;
    padding: 4px;
    transition: transform 0.4s;
    overflow: hidden;

    &:hover {
        transform: scale(1.02);
    }

    .link {
        margin: 0;
        color: inherit;
        text-decoration: none;
    }

    .imageContainer {
        width: 100%;
        padding-top: 100%;
        position: relative;
    }

    .imageSkeleton {
        position: absolute;
        top: 0;
        left: 0;
    }

    .title,
    .priceWrapper {
        position: relative;
    }

    .priceWrapper {
        /*min-height: 36px;*/
    }

    .image {
        border-radius: 16px;
        width: 100%;
        height: 320px;
        /*object-fit: cover;*/
        @mixin smaller-than $mantine-breakpoint-xs {
            height: 168px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        column-gap: 13px;
        padding: 0 8px 12px;

        .title {
            min-height: 40px;
            padding: 0;
            margin: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        .priceWrapper {
            display: flex;
            align-items: flex-end;
            gap: 8px;
            margin: 12px 0 0;

            .price {
                color: #E60033;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px; /* 140% */
                letter-spacing: -0.4px;
            }

            .oldPrice {
                color: #8493A8;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px; /* 175% */
                letter-spacing: -0.16px;
                text-decoration-line: line-through;
                @media (max-width: 768px) {
                    font-weight: 400;
                }
            }
        }
    }
}
